
import { lazy } from 'react'
const Auth=lazy(() => import("pages/Auth/Auth"))
const Home = lazy(() => import("pages/Home/Home"))
const Contact = lazy(() => import("pages/Contact/Contact"))
const Introduce = lazy(() => import("pages/Introduce/Introduce"))
const Product = lazy(() => import("pages/Product/Product"))
const LibSupport = lazy(() => import("pages/LibSupport/LibSupport"))
const NewPapper = lazy(() => import("pages/NewPapper/NewPapper"))
const InstallTools = lazy(() => import('pages/InstallTools/InstallTools'))
const Cart = lazy(() => import('pages/Cart/Cart'))
const routes = [
    {
        path: '',
        component: Home,
        exact: true
    },
    {
        path: '/login',
        component: Auth,
        exact: true
    },
    {
        path: '/contact',
        component: Contact,
        exact: true
    },
    {
        path: '/introduce',
        component: Introduce,
        exact: true
    },
    {
        path: '/product',
        component: Product,
        exact: true
    },
    {
        path: '/library',
        component: LibSupport,
        exact: true
    },
    {
        path: '/new-papper',
        component: NewPapper,
        exact: true
    },
    {
        path: '/install-tool',
        component: InstallTools,
        exact: true
    },
    {
        path: '/cart',
        component: Cart,
        exact: true
    }
   
];

export default routes;
