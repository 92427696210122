import Routers from "./router/Routes"
import './App.css'

function App() {
  return (
      <Routers/>
  );
}

export default App;
